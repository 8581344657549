<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>二维码专区</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="qr-code" v-if="video_data.length !== 0">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in video_data"
          :key="index"
          :label="item.subject_name"
          :name="index.toString()"
        >
          <el-row :gutter="20">
            <el-col
              :span="6"
              v-for="item2 in item.course_list"
              :key="item2.chapter_id"
            >
              <div class="grid-content">
                <div class="pic">
                  <a @click="qrvideo(item2.cc_id, item2.name, item.subject_id)">
                    <p><span class="icon-play-circle"></span></p>
                    <el-image
                      v-if="item2.imgurl === ''"
                      src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
                    />
                    <el-image v-if="item2.imgurl !== ''" :src="item2.imgurl" />
                  </a>
                </div>
                <h4
                  style="overflow: hidden;white-space: nowrap;text-overflow:ellipsis;"
                  :title="item2.name"
                >
                  {{ item2.name }}
                </h4>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div
      class="qr-code"
      style="text-align:center;"
      v-if="video_data.length === 0"
    >
      暂无数据
    </div>
  </div>
</template>

<script>
import { getCourses } from '../api/courses'
import { getIsPay, getResult } from '../utils/auth'

export default {
  name: 'Home',
  data() {
    return {
      licence_id: 59,
      activeName: '0',
      video_data: []
    }
  },
  computed: {},
  created() {
    if (getResult() !== undefined) {
      if (JSON.parse(getResult()).qr_code !== '') {
        if (JSON.parse(getResult()).licence_id === 2) {
          this.licence_id = 59
        } else if (JSON.parse(getResult()).licence_id === 3) {
          this.licence_id = 61
        } else if (JSON.parse(getResult()).licence_id === 4) {
          this.licence_id = 60
        }
        this.getQrvideo()
      } else {
        if (getIsPay() >= 2) {
          if (JSON.parse(getResult()).licence_id === 2) {
            this.licence_id = 59
          } else if (JSON.parse(getResult()).licence_id === 3) {
            this.licence_id = 61
          } else if (JSON.parse(getResult()).licence_id === 4) {
            this.licence_id = 60
          }
          this.getQrvideo()
        } else {
          this.video_data = []
        }
      }
    } else {
      this.getQrvideo()
    }
  },
  methods: {
    getQrvideo() {
      let params = {
        term: 1,
        licence_id: this.licence_id,
        province_id: 0,
        city_id: 0,
        jiaxiao_id: 0,
        appid: 1
      }
      getCourses(params).then(res => {
        if (res.code === 0) {
          this.video_data = res.result.course
        }
      })
    },
    handleClick(val) {
      //console.log(val)
    },
    qrvideo(cc_id, name, subject_id) {
      if (getResult() !== undefined) {
        this.$router.push({
          path:
            '/qrcode/video?cc_id=' +
            cc_id +
            '&name=' +
            name +
            '&subject_id=' +
            subject_id
        })
      } else {
        this.$message({
          type: 'warning',
          message: '请登录后观看！'
        })
      }
    }
  },
  components: {}
}
</script>
